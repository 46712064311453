import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface BearState {
  token?: string;
  isLogin: boolean;
  setToken: (token: string) => void;
  onChangeLogin: (token: string) => void;
  logout: () => void;
}

export const useGlobalStore = create<BearState>()(
  devtools(
    persist(
      (set) => ({
        isLogin: false,
        onChangeLogin: (token) => {
          set({ token, isLogin: true });
        },
        setToken: (token: string) => {
          set({ token });
        },
        logout: () => {
          set({ token: "", isLogin: false });
        },
      }),
      {
        name: "opswiz-store",
      }
    )
  )
);
