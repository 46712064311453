import {
  Button,
  makeStyles,
  shorthands,
  Spinner,
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import auth from "../../../../api/auth";
import { QRCodeSVG } from "qrcode.react";
import OTPInput from "../../../components/otp-input";
import { LoginScreen } from "../type";
import { useGlobalStore } from "../../../../store/global-store";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.gap("20px"),
    ...shorthands.padding("20px"),
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
  },
  buttonContainer: {
    marginTop: "20px",
    display: "flex",
    ...shorthands.gap("10px"),
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonContainerSuccess: {
    marginTop: "20px",
    display: "flex",
    ...shorthands.gap("10px"),
    justifyContent: "flex-end",
    maxWidth: "400px",
    width: "100%",
  },
  button: {
    width: "100%",
    height: "36px",
  },
  backupCodesContainer: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    ...shorthands.gap("2px"),
    marginTop: "20px",
    maxWidth: "400px",
    width: "100%",
    backgroundColor: "#f3f4f6",
    ...shorthands.padding("10px"),
  },
  backupCode: {
    ...shorthands.padding("10px"),
  },
  description: {
    fontSize: "14px",
    color: "#666",
    textAlign: "center",
    maxWidth: "300px",
  },
  copyButton: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
});

export const Setup2FA = ({
  setLoginScreen,
  toasterId,
}: {
  setLoginScreen: (screen: LoginScreen) => void;
  toasterId: string;
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const token = useGlobalStore((state) => state.token);
  const onChangeLogin = useGlobalStore((state) => state.onChangeLogin);
  const [secret, setSecret] = useState("");
  const [otp, setOtp] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const { dispatchToast } = useToastController(toasterId);

  const getSecret = async () => {
    try {
      setIsLoading(true);
      const rs = await auth.setup2fa();
      setSecret(rs.data.data.secret);
      setQrCode(rs.data.data.qr_code);
      setIsLoading(false);
    } catch (error) {
      let message;
      if (error.response?.data) {
        message = error.response.data.message ? error.response.data.message : "Something went wrong";
      }
      dispatchToast(
        <Toast>
          <ToastTitle>
            <div>{message}</div>
          </ToastTitle>
        </Toast>,
        { intent: "error", position: "top-end" }
      );
      setIsLoading(false);
    }
  };

  const onContinue = () => {
    onChangeLogin(token);
    navigate("/");
  };

  useEffect(() => {
    getSecret();
  }, []);

  const handleCopy = () => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line no-undef
      window.navigator.clipboard.writeText(backupCodes.join("\n"));
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const rs = await auth.verify2fa({ code: otp, secret });
      setBackupCodes(rs.data.data);
      setIsSuccess(true);
      setIsLoading(false);
    } catch (error) {
      let message;
      if (error.response?.data) {
        message = error.response.data.message ? error.response.data.message : "Something went wrong";
      }
      dispatchToast(
        <Toast>
          <ToastTitle>
            <div>{message}</div>
          </ToastTitle>
        </Toast>,
        { intent: "error", position: "top-end" }
      );
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setLoginScreen(LoginScreen.LOGIN);
  };

  if (isSuccess) {
    return (
      <div className={styles.container}>
        <h1>Backup verification codes</h1>
        <p className={styles.description}>
          Backup codes are required to access your account if you lose your authenticator app. We recommend printing
          these out and storing them in a secure location.
        </p>
        <div className={styles.backupCodesContainer}>
          <Button size="small" className={styles.copyButton} onClick={handleCopy}>
            Copy
          </Button>
          {backupCodes.map((code) => (
            <div key={code} className={styles.backupCode}>
              {code}
            </div>
          ))}
        </div>
        <div className={styles.buttonContainerSuccess}>
          <Button type="button" appearance="primary" className={styles.button} onClick={onContinue}>
            I&apos;ve save these backup codes
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>Two-factor authentication.</h1>
      <p className={styles.description}>
        Scan the QR code using any authenticator app on your phone (Google Authenticator, Authy, Authenticator, etc.)
      </p>
      {qrCode && <QRCodeSVG value={qrCode} size={250} />}
      <p className={styles.description}>Enter the 6-digit code from your authenticator app</p>
      <OTPInput value={otp} onChange={setOtp} onSubmit={handleSubmit} />
      <div className={styles.buttonContainer}>
        <Button type="button" appearance="outline" className={styles.button} onClick={handleBack}>
          Back
        </Button>
        <Button type="submit" appearance="primary" className={styles.button} onClick={handleSubmit}>
          {isLoading ? <Spinner size="extra-tiny" /> : "Submit"}
        </Button>
      </div>
    </div>
  );
};
