import React, { useRef, KeyboardEvent } from "react";
import { Input } from "@fluentui/react-components";

interface OTPInputProps {
  value: string;
  onSubmit: () => void;
  onChange: (value: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ value, onChange, onSubmit }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // Initialize refs array
  if (inputRefs.current.length === 0) {
    inputRefs.current = Array(6).fill(null);
  }

  const handleChange = (index: number, inputValue: string) => {
    if (inputValue.length <= 1) {
      const newValue = value.split("");
      newValue[index] = inputValue;
      onChange(newValue.join(""));

      // Move to next input if value is entered
      if (inputValue.length === 1 && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !value[index] && index > 0) {
      // Move to previous input on backspace if current input is empty
      inputRefs.current[index - 1]?.focus();
    }
    if (e.key === "Enter" && value.length === 6) {
      onSubmit();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    onChange(pastedData.padEnd(6, ""));
  };

  return (
    <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
      {Array(6)
        .fill(null)
        .map((_, index) => (
          <Input
            key={index}
            value={value[index] || ""}
            onChange={(e) => handleChange(index, e.target.value || "")}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            ref={(ref) => (inputRefs.current[index] = ref)}
            style={{
              width: "40px",
              textAlign: "center",
            }}
            maxLength={1}
          />
        ))}
    </div>
  );
};

export default OTPInput;
