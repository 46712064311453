import * as React from "react";
import { makeStyles, shorthands, useId, Toaster } from "@fluentui/react-components";
import { useGlobalStore } from "../../../store/global-store";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "./components/login-form";
import { LoginScreen } from "./type";
import { OTPForm } from "./components/otp-form";
import { Setup2FA } from "./components/setup-2fa";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    ...shorthands.padding("20px"),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  input: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
  button: {
    width: "100%",
    height: "36px",
  },
});

export const Login = () => {
  const navigate = useNavigate();
  const [loginCredentials, setLoginCredentials] = React.useState({
    email: "",
    password: "",
  });
  const [loginScreen, setLoginScreen] = React.useState(LoginScreen.LOGIN);
  const isLogin = useGlobalStore((state) => state.isLogin);
  const styles = useStyles();
  const toasterId = useId("toaster");

  React.useEffect(() => {
    if (isLogin) {
      navigate("/dashboard");
    }
  }, [isLogin]);

  return (
    <div className={styles.container}>
      <Toaster toasterId={toasterId} />
      {loginScreen === LoginScreen.LOGIN && (
        <LoginForm toasterId={toasterId} setLoginScreen={setLoginScreen} setLoginCredentials={setLoginCredentials} />
      )}
      {loginScreen === LoginScreen.ENTER_OTP && (
        <OTPForm toasterId={toasterId} loginCredentials={loginCredentials} setLoginScreen={setLoginScreen} />
      )}
      {loginScreen === LoginScreen.SETUP_OTP && <Setup2FA toasterId={toasterId} setLoginScreen={setLoginScreen} />}
    </div>
  );
};
