import React, { useState } from "react";
import OTPInput from "../../../components/otp-input";
import {
  Button,
  makeStyles,
  shorthands,
  Spinner,
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import auth from "../../../../api/auth";
import { useGlobalStore } from "../../../../store/global-store";
import { useNavigate } from "react-router-dom";
import { LoginScreen } from "../type";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    ...shorthands.padding("20px"),
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
  },
  buttonContainer: {
    marginTop: "20px",
    display: "flex",
    ...shorthands.gap("10px"),
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    width: "100%",
    height: "36px",
  },
});

export const OTPForm = ({
  loginCredentials,
  setLoginScreen,
  toasterId,
}: {
  loginCredentials: { email: string; password: string };
  setLoginScreen: (screen: LoginScreen) => void;
  toasterId: string;
}) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const onChangeLogin = useGlobalStore((state) => state.onChangeLogin);
  const navigate = useNavigate();
  const styles = useStyles();
  const { dispatchToast } = useToastController(toasterId);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await auth.loginWithOTP({
        email: loginCredentials.email,
        password: loginCredentials.password,
        code: otp,
      });
      const { token } = response.data.data;
      onChangeLogin(token);
      navigate("/dashboard");
      setIsLoading(false);
    } catch (error) {
      let message;
      if (error.response?.data) {
        message = error.response.data.message ? error.response.data.message : "Something went wrong";
      }
      dispatchToast(
        <Toast>
          <ToastTitle>
            <div>{message}</div>
          </ToastTitle>
        </Toast>,
        { intent: "error", position: "top-end" }
      );
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setLoginScreen(LoginScreen.LOGIN);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Enter the 6-digit code from your authenticator app</h2>
      <div className={styles.inputContainer}>
        <OTPInput value={otp} onChange={setOtp} onSubmit={onSubmit} />
        <div className={styles.buttonContainer}>
          <Button type="button" appearance="outline" className={styles.button} onClick={handleBack}>
            Back
          </Button>
          <Button type="submit" appearance="primary" className={styles.button} onClick={onSubmit}>
            {isLoading ? <Spinner size="extra-tiny" /> : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
};
