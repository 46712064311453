import { axiosInstance } from "./axios-interceptors";

const auth = {
  setup2fa: async () => {
    return axiosInstance.put("/v2/users/me/2fa");
  },
  verify2fa: async (data: { code: string; secret: string }) => {
    return axiosInstance.put("/v2/users/me/2fa/verify", data);
  },
  login: async (data: { email: string; password: string }) => {
    return axiosInstance.post("/v2/auth/login", data);
  },
  loginWithOTP: async (data: { email: string; password: string; code: string }) => {
    return axiosInstance.post("/v2/auth/login/2fa", data);
  },
  me: async () => {
    return axiosInstance.get("/v1/users/me");
  },
  authorize: async () => {
    return axiosInstance.get("/v1/auth/authorize");
  },
  logout: async () => {
    return axiosInstance.post("/v1/auth/logout");
  },
  refreshToken: async () => {
    return axiosInstance.post("/v1/auth/refresh");
  },
};

export default auth;
