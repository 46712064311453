import {
  Button,
  Option,
  makeStyles,
  shorthands,
  useId,
  Combobox,
  Toaster,
  useToastController,
  Toast,
  ToastTitle,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import report from "../../api/report";
import insertData, { deleteTable } from "../office-document";
import { useQuery } from "@tanstack/react-query";
import { ArrowExitFilled } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useGlobalStore } from "../../store/global-store";
import Filters from "../components/filters";
import { getHeaders } from "../utils/convert-header";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    ...shorthands.padding("20px"),
  },
  topBar: {
    display: "flex",
    justifyContent: "flex-end",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.gap("2px"),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  control: {
    width: "100%",
  },
  button: {
    width: "100%",
    height: "36px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("20px"),
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  label: {
    fontWeight: "bold",
    display: "flex",
    marginBottom: "6px",
  },
  col: {
    flexGrow: 1,
    position: "relative",
  },
  listbox: {
    maxHeight: "200px",
  },
  datePicker: {
    position: "relative",
  },
  closeWrapper: {
    position: "absolute",
    top: "1px",
    right: "2px",
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    cursor: "pointer",
  },
  icon: {
    width: "14px",
    height: "14px",
  },
});

const convertToSnakeCase = (str: string) => {
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((s) => s.toLowerCase())
      .join("_")
  );
};

export default function Dashboard() {
  const navigate = useNavigate();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const logout = useGlobalStore((state) => state.logout);
  const isLogin = useGlobalStore((state) => state.isLogin);

  const [selectedTemplate, setSelectedTemplate] = React.useState<string>("");

  const [filterValues, setFilterValues] = React.useState({});

  const onChangeFilter = (column, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  const { data: templateData, isLoading: isLoadingTemplate } = useQuery({
    queryKey: ["templates"],
    queryFn: () => report.getTemplates(),
  });

  const templates = templateData?.reportTemplates ?? [];

  const { data: templateDetailData, isFetching: isLoadingTemplateDetail } = useQuery({
    queryKey: ["templateDetail", selectedTemplate],
    queryFn: () => report.getTemplateDetail(selectedTemplate),
    refetchOnWindowFocus: false,
    enabled: !!selectedTemplate,
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const styles = useStyles();

  useEffect(() => {
    if (!isLogin) {
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>
            <div>You are not logged in. Please login to continue. </div>
          </ToastTitle>
        </Toast>,
        { intent: "warning", position: "top-end" }
      );
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, [isLogin]);

  const getDataTemplate = async () => {
    setIsLoading(true);
    try {
      await deleteTable();
      const templateSelected = templates.find((template) => template.uuid === selectedTemplate);

      const filters = templateSelected.filters
        .map((filter) => {
          return {
            column: filter.column,
            value: filterValues[filter.column],
            type: filter.type,
          };
        })
        .filter((filter) => {
          if (Array.isArray(filter.value)) {
            return filter.value.length > 0;
          }

          if (typeof filter.value === "object") {
            const values = Object.values(filter.value);
            return values.some((value) => !!value);
          }
          return !!filter.value;
        });
      const rs = await report.getData(selectedTemplate, filters);
      const headers = getHeaders(rs);
      insertData(headers, rs, convertToSnakeCase(templateSelected.name));
      setIsLoading(false);
      if (rs.length === 0) {
        dispatchToast(
          <Toast appearance="inverted">
            <ToastTitle>
              <div>No data found</div>
            </ToastTitle>
          </Toast>,
          { intent: "warning", position: "top-end" }
        );
      }
    } catch (error) {
      const message = error.response?.data?.errors ? error.response.data.errors[0] : "Something went wrong";
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>
            <div>{message}</div>
          </ToastTitle>
        </Toast>,
        { intent: "error", position: "top-end" }
      );
      setIsLoading(false);
    }
  };

  const onChangeTemplate = (_, data) => {
    setSelectedTemplate(data.optionValue);
  };

  const onLogout = () => {
    logout();
    navigate("/");
  };

  const comboThemeId = useId("theme");

  const templateSelected = templateDetailData;
  return (
    <div className={styles.container}>
      <Toaster toasterId={toasterId} />
      <div className={styles.topBar}>
        <Button appearance="transparent" icon={<ArrowExitFilled />} onClick={onLogout}>
          Logout
        </Button>
      </div>

      <div className={styles.field}>
        <h2>Template</h2>

        <Combobox onOptionSelect={onChangeTemplate} aria-labelledby={comboThemeId} placeholder="Select a template">
          {isLoadingTemplate && (
            <Option value="loading" key="loading" disabled>
              Loading...
            </Option>
          )}
          {templates.map((template) => (
            <Option key={template.uuid} value={template.uuid}>
              {template.name}
            </Option>
          ))}
        </Combobox>
      </div>

      {isLoadingTemplateDetail && (
        <div>
          <Spinner size="large" />
        </div>
      )}

      {templateSelected && !isLoadingTemplateDetail && (
        <Filters filters={templateSelected.filters} onChangeFilter={onChangeFilter} />
      )}

      {templateSelected && !isLoadingTemplateDetail && (
        <div>
          <Button onClick={getDataTemplate} disabled={isLoading} appearance="primary" className={styles.button}>
            {isLoading ? <Spinner size="extra-tiny" /> : "Get Data"}
          </Button>
        </div>
      )}
    </div>
  );
}
