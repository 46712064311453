import * as React from "react";
import {
  makeStyles,
  shorthands,
  Input,
  Button,
  Field,
  Spinner,
  useToastController,
  Toast,
  ToastTitle,
} from "@fluentui/react-components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import auth from "../../../../api/auth";
import { LoginScreen } from "../type";
import { useGlobalStore } from "../../../../store/global-store";

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    ...shorthands.padding("20px"),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  input: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
  button: {
    width: "100%",
    height: "36px",
  },
});

export const LoginForm = ({
  toasterId,
  setLoginScreen,
  setLoginCredentials,
}: {
  toasterId: string;
  setLoginScreen: (screen: LoginScreen) => void;
  setLoginCredentials: (credentials: { email: string; password: string }) => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const styles = useStyles();
  const form = useForm({ resolver: zodResolver(schema) });
  const { dispatchToast } = useToastController(toasterId);
  const setToken = useGlobalStore((state) => state.setToken);
  const {
    formState: { errors },
  } = form;

  const onsubmit = async (data) => {
    try {
      setIsLoading(true);
      setLoginCredentials(data);
      const rs = await auth.login(data);
      const { token } = rs.data.data;
      setToken(token);
      await auth.me();
    } catch (error) {
      if (error.response?.status === 412 && error.response?.data?.data?.code === "code_required") {
        setLoginScreen(LoginScreen.ENTER_OTP);
        return;
      }
      if (error.response?.status === 412 && error.response?.data?.data?.code === "2fa_required") {
        setLoginScreen(LoginScreen.SETUP_OTP);
        return;
      }
      let message;
      if (error.response?.data) {
        message = error.response.data.message ? error.response.data.message : "Something went wrong";
      }
      dispatchToast(
        <Toast>
          <ToastTitle>
            <div>{message}</div>
          </ToastTitle>
        </Toast>,
        { intent: "error", position: "top-end" }
      );
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2 className={styles.header}>Login to Opswiz</h2>
      <form className={styles.formContainer} onSubmit={form.handleSubmit(onsubmit)}>
        <Field
          label="Email"
          validationState={errors.email ? "error" : "none"}
          validationMessage={errors.email ? "Please enter a valid email" : undefined}
        >
          <Input {...form.register("email")} />
        </Field>

        <Field
          label="Password"
          validationState={errors.password ? "error" : "none"}
          validationMessage={errors.password ? "Please enter a password" : undefined}
        >
          <Input type="password" {...form.register("password")} />
        </Field>

        <div>
          <Button type="submit" appearance="primary" className={styles.button}>
            {isLoading ? <Spinner size="extra-tiny" /> : "Login"}
          </Button>
        </div>
      </form>
    </div>
  );
};
