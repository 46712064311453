import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import { Routes } from "react-router-dom";
import { HashRouter, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../query-client";
import { Login } from "./Login";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <div>
            <Routes>
              <Route path="/" Component={Login} />
              <Route path="/dashboard" Component={Dashboard} />
            </Routes>
          </div>
        </HashRouter>
      </QueryClientProvider>
    </div>
  );
};

export default App;
